import { LoanProgram } from '@lendinghome/core/constants';

export const RESET_PASSWORD_TREATMENT_NAME = 'enable_auth0_reset_password';
export const ENABLE_REQUIRE_ENTITY_BORROWER_ON_REPEAT = 'enable_require_entity_borrower_on_repeat';
export const ENABLE_BLOCK_BORROWERS_WITH_ZERO_EXITS_ON_REPEAT = 'enable_block_repeat_borrowers_with_zero_exits';
export const ENABLE_PARTNERSTACK_NEW_REFER_URL = 'enable_partnerstack_new_refer_url';

export const PROGRAM_TO_START_NEW_LOAN_URL_PARAM_MAPPINGS = {
  [LoanProgram.HARD_MONEY]: 'bridge',
  [LoanProgram.HARD_MONEY_INFILL]: 'bridge',
  [LoanProgram.RENTAL]: 'rental',
};

export const REHAB_TYPES = {
  INFILL: 'INFILL',
};
